import 'owl.carousel';

jQuery( document ).ready( function ( $ ) {
	$( '#listen .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		center: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right"></i><span class="sr-only">Next</span>'
		]
	} );

	$( '#watch .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		video: true,
		center: true,
		dots: false,
		nav: true,
		navText: [
			'<i class="fas fa-arrow-left"></i><span class="sr-only">Prev</span>',
			'<i class="fas fa-arrow-right"></i><span class="sr-only">Next</span>'
		]
	} );
} );